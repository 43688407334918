import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Resurfacing = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-interiors.jpg" className="top">
      <h1>Pool Resurfacing</h1>
    </BgImage>
    <section className="content">
      <h2>Painted Pools</h2>
      <p>
        Painting your pool is most cost effective method to make an old pool
        look new and rejuvenated. Paints come in a variety of different colours
        and are a two-part epoxy resin. The typical life span of paint is
        between seven to ten years. Typically we perform surface preparation
        which involves grinding back any loose / flaking paint, then we acid
        etch the surface and finally we use a heavy duty detergent to wash the
        surface. Next, we apply tape to any areas we do no wish to allow paint
        to touch and at this time we also apply the first coat of paint and cut
        in under the tile line. Then, on the final day we apply the final coat
        of paint and again cut in under the tile line. The pool is then left for
        ten days to cure. After ten days the pool is refilled with water.
        Painting the pool is ideal for people wishing to sell their house in the
        near future.
      </p>
      <p>
        Looking to renovate your pool so that it looks great but only have a
        limited budget? Then repaint your pool and make rejuvenate the surface
        so that it looks and feels just like a newly built swimming pool. Our
        renovations are perfect for new or old cement rendered pools, fibreglass
        pools, marblesheen and previously epoxy painted pools. Painting your
        pool gives you the option of wide range of colours, low cost and low
        maintenance solution.
      </p>
      <p>
        <strong>Painting a pool is a multistep process consisting of:</strong>
      </p>
      <ol>
        <li>Grinding back any loose / flaking paint</li>
        <li>
          Acid etching the surface to help open the pores of the substrate
        </li>
        <li>
          Cleaning the concrete using a heavy-duty detergent to remove any grime
          from the surface
        </li>
        <li>Placing tape on any areas we do no wish to allow paint to touch</li>
        <li>
          Applying the first coat of paint and cutting in under the tile line
        </li>
        <li>
          Applying the final coat of paint and again cutting in under the tile
          line
        </li>
        <li>Allowing the paint to cure for ten days</li>
        <li>Refilling the pool with tap water</li>
      </ol>
      <img
        src="/assets/Luxapool-Colour-Chart.png"
        className="img-responsive"
        alt="Luxapool Colour Chart"
      />
      <h2>Fibreglassed Pools</h2>
      <p>Nuplex® Fibreglass for In-ground Concrete Pools </p>
      <p>
        Nuplex® fibreglass is an extrememly durable surface and provides a
        non-permeable barrier to water. By being non-permeable it provides
        protection to the concrete surface beneath. Fibreglass has five coats:
        i. Primer Coat / Sealing Layer for Substrate ii. Polyester Resin iii.
        Woven Roven Glass Cloth iv. Vinyl Ester Resin v. Colour Gel-coat. It is
        highly resistant to damage from chemicals and does not easily stain. It
        is ideal for people who had problems with leaks in the past or who wish
        to have a very low maintenance pool.
      </p>
      <h2>Pebble Pools</h2>
      <p> </p>
      <h2>Glass Bead – Jewels for Pools</h2>
      <p>
        The crystal glass pool lining requires minimal maintenance, is mooth on
        feet, non-toxic and visually appealing. It contains a special geopolymer
        designed specifically for pool renders containing glass or crystal.
        Jewels for Pools is gives you freedom of choice with colour with lots of
        different choices to mix together.
      </p>
      <p>Colours Available:</p>
      <ul>
        <li>Blue</li>
        <li>Cobalt</li>
        <li>Pearl</li>
        <li>Black</li>
        <li>Green</li>
      </ul>
      <h2>Quartzon</h2>
      <p>
        Made from a high density mix of quartz, ceramically coloured stones and
        specialized sands, Quartzon pools are smooth to touch, yet firm under
        foot. Quartzon contains a special additive, “starfleck”. This captures
        and reflects light to create a shimmering pool both on a hot summers day
        or late at night from your pool light. Quartzon is a truly unique pool
        interior that creates amazing visual effects in pool water.
      </p>
      <p>Colours Available:</p>
      <ul>
        <li>White Beach</li>
        <li>Santorini</li>
        <li>Ice Blue</li>
        <li>Sky Bay</li>
        <li>Pacific Star</li>
        <li>Royal Blue</li>
        <li>Calypso</li>
        <li>Graphite</li>
      </ul>
      <h2>Seychellia / Aristone</h2>
      <p>
        Seychellia and Aristone is the trade names of resurfacing renders made
        by River Sands Pty Ltd. Seychellia compromises a pre-mixed blend of
        cement and coloured crystal spheres whilst Aristone is a pre-mixed blend
        of ceramically fired stones and cement. Both Seychellia and Aristone are
        smoother than pebbles.
      </p>
      <p>Seychellia Colours </p>
      <ul>
        <li>Akoya</li>
        <li>Keshi</li>
        <li>Mabé</li>
        <li>Melo</li>
        <li>Tahitian</li>
      </ul>
      <p>Aristone Colours </p>
      <ul>
        <li>Brilliant Aqua</li>
        <li>Brilliant Blue</li>
        <li>Brilliant Stone</li>
        <li>Crisp Blue</li>
        <li>Crisp White</li>
      </ul>
      <h2>Tiled Pools</h2>
      <p>
        We use Australia’s largest supplier and importer of tiles for Swimming
        Pools, The Pool Tile Company. When renovating your pool a fully tiled
        pool gives you a long wearing surface that has that extra edge of
        luxury. We have an assortment of tiles available: glass mosaics, ceramic
        mosaics, porcelain mosaics and stacked stones. To see just some of the
        tile options available please see <Link to="/work/tiling">here.</Link>
      </p>
    </section>
  </Layout>
)

export default Resurfacing
